import React, {useState} from 'react'

const Calculator= ()=>{
    const credits = [10,15,20,30,60]
    const levels = ['Level 2', 'Level 3']
    const [creditList, setCreditList] = useState([])
    const wrapCredits = (list) => list.map( item => <li key={item}>{item}</li>)
    
    return(
       <div className="container mx-auto mt-3 text-center">
       <div className="p-1 divide-x-2">
       <label htmlFor="credits" className="p-2 text-lg font-bold text-gray-600">Select credits </label>   
       <select name="credits" className="rounded p-2 text-lg bg-blue-600 text-white" id="credits">
       <option value="null">---</option>
       {credits.map(
           credit => <option value={credit} key={credit}>{credit}</option> 
       )
       }    
       </select> 
       <select name="level" className="rounded p-2 text-lg bg-blue-600 text-white" id="level">
           <option value="null" >---------</option>
           {
               levels.map(
                   (level, index) => <option value={index+2} key={index}>{level}</option>
               )
           }
       </select>
       <button onClick={()=>{
           const [selectedCredit, selectedLevel] = [document.getElementById("credits").value, document.getElementById('level').value]
           setCreditList(creditList.concat(`credit: ${selectedCredit} - level ${selectedLevel}`))           
       }}
       className="p-2 ml-2 bg-blue-600 rounded text-lg text-white">add</button>
      </div>     
        <div className="mt-3">
        <div className="p-2 text-lg font-bold text-gray-600">
            <label htmlFor="list">modules added</label>
            <ul>
                {
                    creditList.length>0?wrapCredits(creditList):0
                }    
            </ul>
        </div>
       <p className="p-2 text-lg font-bold text-gray-600">
           points left 240
       </p>
        </div>
        </div>
    )
}

export default Calculator